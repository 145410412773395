import { isLocal } from "../lib/envFunctions";

// notion page with all the content:
// https://www.notion.so/Vimcal-Help-Center-9b4e05b3fc8d44338fc444fb1399d72d

// hierarchy is:
// - section
//   - subsection
//     - topic
//       - header
//       - body
export const CONTENT_TYPE = {
  TEXT: "text",
  VIDEO: "video",
  IMAGE: "image",
  LINK: "link",
  LIST: "list",
};

export const LIST_TYPE = {
  DEFAULT: "default",
  BULLET_NON_INDENTED: "bullet_non_indented",
  BULLET_INDENTED: "bullet_indented",
};

export const VIDEO_TYPE = {
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
};

export const CONTENT_PATH = {
  ABOUT: "about",
  VIMCAL_BIG_THREE: "vimcal-big-three",
  TIME_TRAVEL: "time-travel",
  TEAMS: "teams",
  ACCOUNT_SETUP: "account-setup",
  REFER_A_FRIEND: "refer-a-friend",
  MOST_POPULAR: "most-popular",
  CONFERENCING: "conferencing",
  INTERNAL_SCHEDULING: "internal-scheduling",
  GROUP_VOTE: "group-vote",
  CONTACT_GROUPS: "contact-groups",
  EDIT_YOUR_PROFILE: "edit-your-profile",
  PLANS_AND_PRICING: "plans-and-pricing",
  TEMPLATES: "templates",
  SLOTS: "slots",
  FOCUS_MODE: "focus-mode",
  TAGS_AND_METRICS: "tags-and-metrics",
  PERSONAL_LINKS: "personal-links",
  COMMAND_CENTER: "command-center",
  NLP: "nlp",
  TIME_ZONES: "time-zones",
  EXTERNAL_SCHEDULING: "external-scheduling",
  KEYBOARD_SHORTCUTS: "keyboard-shortcuts",
  EVENTS: "events",
  FAQ: "faq",
  FREE_TIME_FINDER: "free-time-finder",
  COLOR_CODING_AND_TAGGING: "color-coding-and-tagging",
  WEEKLY_METRICS: "weekly-metrics",
  POSSIBLE_ISSUES_GOOGLE: "possible-issues-google",
  INSANT_OPEN: "instant-open",
};

function getCompleteURL(path) {
  // if (isLocal()) {
  //   return `http://localhost:3001/${path}`;
  // }
  return `https://docs.vimcal.com/${path}`;
}

const WHAT_IS_VIMCAL_SUBSECTION = {
  name: "What is Vimcal?",
  path: CONTENT_PATH.ABOUT,
  topics: [
    {
      header: "About",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "We designed Vimcal to be the world’s fastest calendar for busy people and their teams! Think of it as the calendar for people with too many meetings.",
        },
      ],
    },
    {
      header: "Product demo",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "get a quick overview of the calendar",
        },
        {
          type: CONTENT_TYPE.VIDEO,
          video_type: VIDEO_TYPE.YOUTUBE,
          src: "https://www.youtube.com/embed/NozZ57u0f38?si=5eX3nghm0oGu1wiC", // need to put the embed version of the URL here
        },
      ],
    },
    {
      header: "Getting Started",
      bodies: [
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.ACCOUNT_SETUP),
          label: "Account Setup",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.CONFERENCING),
          label: "Conferencing",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.MOST_POPULAR),
          label: "Most Popular Features",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.VIMCAL_BIG_THREE),
          label: "Vimcal Big 3",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TEAMS),
          label: "Vimcal For Teams",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Account Setup",
              link: getCompleteURL(CONTENT_PATH.ACCOUNT_SETUP),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Refer a friend",
              link: getCompleteURL(CONTENT_PATH.REFER_A_FRIEND),
            },
          ],
        },
      ],
    },
  ],
};

const PERSONAL_LINKS_SUBSECTION = {
  name: "Personal Links",
  path: CONTENT_PATH.PERSONAL_LINKS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Personal Links are reusable booking links where you can set times you'd like to offer every week, e.g. 30-minute sales calls or hour-long office hours.",
        },
      ],
    },
    {
      header: "Choosing times to offer",
      bodies: [
        {
          text: "On the left-hand calendar, drag the time windows you'd like to offer on a weekly basis.",
        },
        {
          text: "We'll show these as bookable times every week to anyone who has your Personal Link, minus any conflicting events on your calendar marked as 'busy'.",
        },
        {
          text: "To block an entire day from being booked, e.g. you're flying that day, create an all-day 'busy' event.",
        },
        {
          text: "'Free' events will not count as conflicts and recipients will be able to book over them.",
        },
      ],
    },
    {
      header: "Label",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "The label section is the title that appears on the booking page.",
        },
        {
          text: "Please note, this differs from the title of the event that gets created (see below).",
        },
      ],
    },
    {
      header: "Nickname",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "The nickname section allows you to add a title that only you can see.",
        },
        {
          text: "This is useful when differentiating between Personal Links that have similar purposes but with small differences that you don't want to communicate to the recipient.",
        },
        {
          text: "For example, you may want to distinguish between 'fundraise 30m', 'fundraise 45m', 'fundraise (EDT only)', and 'fundraise (high-priority)'.",
        },
      ],
    },
    {
      header: "URL",
      bodies: [
        {
          text: "Easily customize your Personal links URL to better fit the nature of the meeting or your brand.",
        },
      ],
    },
    {
      header: "Time zone",
      bodies: [
        {
          text: "Dictate which time zones your Personal Link will default to.",
        },
        {
          text: "If you travel frequently, you can create separate links for the same meeting type but in different time zones. Discern them with different nicknames (see 'Nickname' above).",
        },
      ],
    },
    {
      header: "Calendar Invite Settings",
      bodies: [
        {
          text: "Calendar invite relates to the event details when a recipient schedules through your Personal Link. ",
        },
        {
          text: "Customize the event's title, duration, attendees list, conferencing option/location, and more.",
        },
      ],
    },
    {
      header: "Custom Questions",
      bodies: [
        {
          text: "Easily add additional fields to your booking page. ",
        },
        {
          text: "Pro Tip: If you enable the 'Company name' Cusotm Question, you can use it as a variable when populating the title of the invite that gets created.",
        },
      ],
    },
    {
      header: "Scheduling Settings",
      bodies: [
        {
          text: "In the bottom section of a Personal Link, you can set parameters for the times which show up in the booking link for the recipients.",
        },
        {
          text: "For example, you can dictate buffers to keep before or after meetings, how far in the future people can book, reminder emails, and rules for conflicts.",
        },
      ],
    },
    {
      header: "Choosing calendars to protect from conflicts",
      bodies: [
        {
          text: "The 'Check for conflicts' feature lets you select which calendars you'd like to prevent double booking on.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Booking links you send out will hide any conflicting events you have on those calendars.",
        },
      ],
    },
    {
      header: "Ignoring conflicts",
      bodies: [
        {
          text: "On the other hand, there are times you'll want to double book over existing meetings on your calendar, like when you need to schedule a high-priority meeting or a job interview.",
        },
        {
          text: "You get two override options in Vimcal:",
        },
        {
          text: "Ignore all conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on if you want the booking link to disregard all conflicts on your calendar and always show all the selected Slots to the recipient.",
        },
        {
          text: "Pro Tip: Reserve this for the highest priority meetings to prevent accidental double bookings.",
        },
        {
          text: "Ignore my internal conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on to allow recipients to book over meetings you define as 'interal'.",
        },
        {
          text: "Pro Tip: Set what company domains or individual email addresses count as an internal meeting in your General Settings.",
        },
        {
          text: "--- ADD LINK TO SECTION OF DOCS ARTICLE FOR INTERNAL DOMAINS. NAME THIS LINK 'View Internal Meeting Settings'",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Sharing your Personal Links",
      bodies: [
        {
          text: "There are two ways to share your booking link with someone else.",
        },
        {
          text: "First, you can copy just the URL. Copy a given link's URL from anywhere within Vimcal by simply using the global hotkeys 'YY1', 'YY2', etc.",
        },
        {
          text: "Hover over the 'Copy Link' button to see that link's specific shortcut.",
        },
        {
          text: "--- IMAGE OF SHORTCUT WHEN HOVERING OVER COPY LINK BUTTON",
        },
        {
          text: "Secondly, you can copy a text block with a few handpicked time. We check your link that these are still available.",
        },
        {
          text: "This will resemble the text you copy from our Slots feature and allow you convenience without losing the human.",
        },
        {
          text: "--- IMAGE OF SLOTS WHEN HOVERING OVER COPY SHARE -> COPY SLOTS",
        },
      ],
    },
    {
      header: "Embedding links on your website",
      bodies: [
        {
          text: "You can embed Personal Links on your company's website to help drive conversion.",
        },
        {
          text: "Click 'Share' and the 'Embed on website' option, then simply copy and paste the HTML into your website's code.",
        },
      ],
    },
  ],
};

const INSTANT_OPEN_SUBSECTION = {
  name: "Instant Open",
  path: CONTENT_PATH.INSANT_OPEN,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Quickly open any invite or URL in Vimcal even faster with Instant Open!",
        },
        {
          text: `Simply type "Cmd + O" and the title of an event you see on your calendar.`,
        },
        {
          text: "If an event is already selected, Instant Open will also let you open any URLs in that event's description.",
        },
      ],
    },
  ],
};

const NLP_SUBSECTION = {
  name: "Creating events with NLP",
  path: CONTENT_PATH.NLP,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "One of the most used features in Vimcal is creating meetings with Natural Language (NLP).",
        },
        {
          text: "In the purple text input at the top of the event form, you can type things like 'Coffee tomorrow at 3pm with John' and Vimcal will fill in all the details for you!",
        },
        {
          text: "Once you get a taste, you'll never want to go back to manually filling out each field.",
        },
        {
          text: "--- GIF OF NLP",
        },
      ]
    },
    {
      header: "The Golden Rule: Title First",
      bodies: [
        {
          text: "There's only one rule to follow when using the NLP feature — always start with the title of the event.",
        },
        {
          text: "After that, you can type out the remaining details in any order.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Here are 2 examples that have the same information, but are written in different orders.`,
            },
            {
              text: `"Coffee tomorrow at 5pm CST with michael@dundermifflin.com at Central Perk for 45 minutes"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: '"Coffee at Central Perk with michael@dundermifflin.com for 45 minutes at 5pm CST tomorrow"',
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Keywords",
      bodies: [
        {
          text: "We detect the field you want to update via intuitive keywords:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"For" → duration`,
            },
            {
              text: `"Coffee for 45 minutes"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Design sync for 1 hr 30 min"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"With" → attendees`,
            },
            {
              text: `"Coffee with michael@dundermifflin.com and dwight@dundermifflin.com"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"At" → location`,
            },
            {
              text: `"Coffee at Central Perk"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"On" or "via" → Conferencing solution`,
            },
            {
              text: `"Product Review on Zoom"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Catch up via phone"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Date and Time",
      bodies: [
        {
          text: "You can type the time and date in any order you'd like.",
        },
        {
          text: "For example, 'Tuesday at 3pm', '3pm Tuesday', or '3pm with John on 6/4' will all give you the correct date and time.",
        },
      ],
    },
    {
      header: "Specifying time zone",
      bodies: [
        {
          text: "You can add a time zone immediately by typing the abbreviation immediately after the start time of the meeting.",
        },
        {
          text: "For example: 'Sprint plan at 5pm CST'.",
        },
        {
          text: "We'll even activate Time Travel on your behalf so you can double check that the time works for your time zone!",
        },
      ],
    },
    {
      header: "Switching to a different calendar",
      bodies: [
        {
          text: "If you want to save the event on a calendar different than the current default, simply type '/' and you'll see a dropdown of other calendars you can write the event to.",
        },
        {
          text: "--- IMAGE OF DROPDOWN AFTER PRESSING /",
        },
      ],
    },
    {
      header: "Applying event templates",
      bodies: [
        {
          text: "To move even quicker, you can type the name of an event template directly in the NLP input to quickly pre-fill the details of the meeting.",
        },
        {
          text: "--- IMAGE OF TEMPLATE DROP DOWN",
        },
        {
          text: "If you haven't fully explored our templates feature, click on the link below to learn more: ",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TEMPLATES),
          label: "Templates & Sticky Notes",
        },
      ],
    },
  ],
};

const COMMAND_CENTER_SUBSECTION = {
  name: "Command Center",
  path: CONTENT_PATH.COMMAND_CENTER,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "The Command Center is Vimcal's central hub.",
        },
        {
          text: "It allows you to access every feature and setting within Vimcal without ever leaving your keyboard.",
        },
        {
          text: "Hit Cmd + K (Mac) or Ctrl + K (PC) and start typing out a command.",
        },
      ],
    },
    {
      header: "Contextual menu",
      bodies: [
        {
          text: "The suggested options in the Command Center will change depending on what you're doing in the app.",
        },
        {
          text: "For example, you'll see a different set of options when you open it while looking at your calendar vs. when you're creating an invite.",
        },
        {
          text: "Any option that also has a keyboard shortcut will be displayed so you can learn it and eventually skip the speedy Command Center in the future.",
        },
        {
          text: "--- IMAGE OF COMMAND CENTER AND CONTEXTUAL MENU TO ACCEPT/DECLINE INVITE"
        }
      ],
    },
    {
      header: "Jumping to specific dates ",
      bodies: [
        {
          text: "With the Command Center, it's never been easier to jump to a specific date on your calendar.",
        },
        {
          text: "The best part is that it can accept Natural Language to navigate on your behalf. Try typing 'July 24, 2025' or 'Last Friday'.",
        },
      ],
    },
    {
      header: "Changing settings",
      bodies: [
        {
          text: "You can set most of your settings directly in the Command Center! Just press Cmd + K (Mac) or Ctrl + K (PC) and type in the setting.",
        },
        {
          text: "Try 'Dark Mode' to toggle the theme in Vimcal.",
        },
        {
          text: "Optionally, you can type “Settings” to view all your settings within Vimcal.",
        },
      ],
    },
    {
      header: "Shortcuts ",
      bodies: [
        {
          text: "If you ever forget a shortcut or where to find something, you can always default back to the Command Center.",
        },
      ],
    },
  ],
};

const POSSIBLE_ISSUES_GOOGLE_SUBSECTION = {
  name: "Possible blockers you may encounter (Google)",
  path: CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE,
  topics: [
    {
      header: "You're stuck on the sign-in screen",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "This could be for a multitude of reasons but the two most common reasons are:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "1. You haven’t checked off all permissions",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "To resolve, select ‘Sign in’ one more time and check off any permissions you may have missed.",
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "2. Browser is blocking cookies",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "If using Chrome, go to the address bar and look all the way to the right. You may find an eyeball icon with a slash through it.",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.amazonaws.com/cookies.png",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Select “Site not working?” → “Allow cookies”",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Retry sign-in",
            },
          ],
        },
      ],
    },
    {
      header: "400 Error",
      bodies: [
        {
          label:
            "Linked here is a one-minute Loom showing how to lift 400 errors.",
          src: "https://www.loom.com/share/28980f30684b487694d6c1fa65370c07",
          type: CONTENT_TYPE.LINK,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Someone with GSuite Admin Access goes to the Admin Console",
            },
            {
              text: "Step 2: On the left, select ‘Security’ → ‘Access and data control’ → ‘API controls’",
            },
            {
              text: "Step 3: Select MANAGE THIRD-PARTY APP ACCESS on the right",
            },
            {
              text: "Step 4: Near the middle-left of your screen, select the ‘Add app’ dropdown → ‘Auth App Name Or Client ID’",
            },
            {
              text: "Step 5: In the Search bar, type ‘Vimcal’ and select the blue Search button",
            },
            {
              text: "Step 6: ‘Select’ Vimcal on the right and check all configuration boxes",
            },
            {
              text: "Step 8: Choose “Trusted: Can access all Google services”",
            },
            {
              text: "Step 9: Choose “Trusted: Can access all Google services” and select CONFIGURE",
            },
          ],
        },
        {
          label: "Step 10: Sign into Vimcal",
          src: "https://calendar.vimcal.com/login",
          type: CONTENT_TYPE.LINK,
        },
      ],
    },
  ],
};

const REFER_A_FRIEND_SUBSECTION = {
  name: "Refer a friend",
  path: CONTENT_PATH.REFER_A_FRIEND,
  topics: [
    {
      bodies: [
        {
          text: "Enjoy using Vimcal? Invite your co-workers and friends to give them access too! ",
        },
        {
          text: "Cmd + K (Mac) or Ctrl + K (PC) and typing refer should get you there! ",
        },
        {
          text: "--- IMAGE OF REFERRAL PORTAL",
        },
        {
          text: "You can share your referral URL (recommended) or send them an invite email through Vimcal.",
        },
        {
          text: "If the person you referred ends up joining, you'll both receive a free month! ",
        },
      ],
    },
  ],
};

const ACCOUNT_SETUP_SUBSECTION = {
  name: "Account Setup",
  path: CONTENT_PATH.ACCOUNT_SETUP,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Welcome to Vimcal! Let’s get you set up.",
            }
          ]
        }
      ]
    },
    {
      header: "Using the Vimcal Desktop App",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Download the Vimcal Desktop app for your device:",
            },
            {
              text: "Mac with Apple Silicon (M1,M2,M3,M4)",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-m1.s3.us-west-1.amazonaws.com/Vimcal-1.0.30-arm64.dmg",
            },
            {
              text: "Mac with Intel",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-production.s3.us-west-1.amazonaws.com/Vimcal-1.0.30.dmg",
            },
            {
              text: "Windows",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-pc.s3.amazonaws.com/Vimcal-1.0.30.exe",
            },
          ],
        },
      ],
    },
    {
      header: "Using Vimcal in the browser",
      bodies: [
        {
          text: "You may use Vimcal in your browser of choice. The experience will be mostly the same as that of the Vimcal Desktop app, with the exception of the Menu Bar App for Mac users.",
        },
        {
          label: "Start using Vimcal in the Browser.",
          type: CONTENT_TYPE.LINK,
          src: "https://calendar.vimcal.com"
        },
      ],
    },
    {
      header: "Authenticating with Google",
      bodies: [
        {
          text: "Please make sure to check all of the permissions in the Google login flow in order to proceed.",
        },
        {
          label: "Possible issues you may encounter.",
          src: getCompleteURL(CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE),
          type: CONTENT_TYPE.LINK,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.amazonaws.com/permissions-screen.png",
        },
      ],
    },
    {
      header: "Permissions",
      bodies: [
        {
          text: "Below are the permissions we request and how we use them:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "View calendar resources on your domain.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows you to book conference rooms for your meetings.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See info about users on your domain.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See and download contact info automatically saved in your “Other contacts”.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "We will never contact, sell, or share your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See and download your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "We will never contact, sell, or share your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See, edit, share, and permanently delete all the calendars you can access using Google Calendar.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This is simply 'read & write access' to your calendar, which is a necessity for basic functionality to work in a calendar app.",
            },
          ],
        }
      ],
    },
    {
      header: "Microsoft Sign in flow",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Confirm you’re using either Chrome or Brave as your browser",
            },
            {
              text: "Step 2: Go to https://calendar.vimcal.com/home",
            },
            {
              text: "Step 3: Select ‘Sign in with Microsoft’, using your work email",
            },
            {
              text: "Step 4: Accept all permissions",
            },
          ],
        },
      ],
    },
    {
      header: "Related articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Most popular",
              link: getCompleteURL(CONTENT_PATH.MOST_POPULAR),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Refer a friend",
              link: getCompleteURL(CONTENT_PATH.REFER_A_FRIEND),
            },
          ],
        },
      ],
    },
  ],
};

const CONFERENCING_SUBSECTION = {
  name: "Conferencing",
  path: CONTENT_PATH.CONFERENCING,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Vimcal supports direct integrations with Zoom, Google Meet, and Microsoft Teams, as well as custom conferencing options.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "To access your conferencing settings, go to the “Conferencing” section of your account settings.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Alternatively, you can access it via the Command Center by pressing Cmd + K (Mac) or Ctrl + K (PC) and typing “Conferencing Settings”.",
        },
      ]
    },
    {
      header:
        "Zoom",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 1: Click the blue 'Login' button to authenticate your Zoom account.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF ZOOM LOGIN BUTTON_____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 2: In the Zoom pop-up module, sign in with your corresponding email account. You may need to scroll down to view sign-in options.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 3: Check the box “Allow this app to use my shares access permissions”. (Note: this is crucial for the integration to work correctly so please don’t skip this step!)",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF ZOOM PERMISSIONS CHECKBOX____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 4: Click 'Allow'. When prompted to return back to Vimcal, select 'Open Vimcal'.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Once complete, you should see the word 'Authenticated' where the blue Login button used to be.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Bonus: If you'd like to set Zoom as your default conferencing option, click on 'Make default' next to the Zoom header.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF DEFAULT CONF SCREENSHOT_____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Google Meet",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "When signing into Vimcal with a Google account, we carry over the option to use Google Meet for all meetings. No setup is required.",
        },
      ],
    },
    {
      header: "Microsoft Teams",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "When signing in with a Microsoft or Outlook account, we sync with your Microsoft Teams account for all meetings. No setup is required.",
        },
      ],
    },
    {
      header: "Custom Conferencing",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "If you'd like to use any other third-party conferencing solution, you can easily add the personal meeting link name and URL for that platform here.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF CUSTOM CONF SCREENSHOT_____",
        },
      ],
    },
  ],
};

const TEAMS_SUBSECTION = {
  name: "Vimcal for Teams",
  path: CONTENT_PATH.TEAMS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal is designed specifically for busy teams.",
        },
        {
          text: "After using Vimcal, your team will move faster through the calendar and reduce time spent scheduling both internal and external meetings.",
        },
      ],
    },
    {
      header: "Creating a Team",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `1. Access the Command Center via Cmd + K (Mac) or Ctrl + K (PC) → 'Team plan' or go to the Team section of your Settings.`,
            },
            {
              imageSrc: "", // TODO: add
            },
            {
              text: "2. Once there, you may either 'Create team' or 'add teammates'",
            },
            {
              imageSrc: "", // TODO: add
            },
            {
              text: "3. You're done!",
            },
          ],
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: `If you'd like to remove any team members, it can be done in the same 'Team' area by the admin of the Team Plan.`,
        },
      ],
    },
    {
      header: "Team Features",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: `Once you've onboarded you're team onto Vimcal, you'll want to take advantage of our team features:`,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Internal Scheduling",
              link: getCompleteURL(CONTENT_PATH.INTERNAL_SCHEDULING),
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Group Vote",
              link: getCompleteURL(CONTENT_PATH.GROUP_VOTE),
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Contact Groups",
              link: getCompleteURL(CONTENT_PATH.CONTACT_GROUPS),
            },
          ],
        },
      ],
    },
  ],
};

const EDIT_YOUR_PROFILE_SUBSECTION = {
  name: "Profile and Social Links",
  path: CONTENT_PATH.EDIT_YOUR_PROFILE,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "This section will cover how to customize your externally-facing profile when people schedule with you.",
        },
      ],
    },
    {
      header: "Profile Photo & Social Links",
      bodies: [
        {
          text: "Your photo and social links will appear in two places.",
        },
        {
          text: "First is your booking links. This adds a human touch when other people book with you, and also allows them to connect with you prior to the meeting.",
        },
        {
          text: "--- IMAGE HERE OF BOOKING LINK",
        },
        {
          text: "Secondly, these will both show in the attendees section of an event you're invited to, visible only to other Vimcal users.",
        },
        {
          text: "--- IMAGE HERE OF ATTENDEE SECTION",
        },
      ],
    },
    {
      header: "Username",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Your username is your unique identifier that's used in all your booking links. For example, if you set your username to 'johnsmith', your Personal Link for a 30 minute meeting will be:",
        },
        {
          text: "'https://book.vimcal.com/p/johnsmith/30min'",
        },
        {
          text: "Please note that changing your username will break all existing links you've shared publicly, so please make sure to update them and change this setting sparingly.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    }
  ],
};

const PLANS_AND_PRICING_SUBSECTION = {
  name: "Plans and Pricing",
  path: CONTENT_PATH.PLANS_AND_PRICING,
  topics: [
    {
      header: "Plans",
      bodies: [
        {
          type: CONTENT_TYPE.LINK,
          src: "https://www.vimcal.com/pricing",
          label: "View all plans and pricing",
        },
      ],
    },
  ],
};

const WHERE_TO_FIND_CONTENT = {
  type: CONTENT_TYPE.TEXT,
  text: "Where to find",
  isSubheader: true,
};

const MOST_POPULAR_SUBSECTION = {
  name: "Most popular features",
  path: CONTENT_PATH.MOST_POPULAR,
  topics: [
    {
      header: "Slots",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: `The fastest way to share your availability with others! Vimcal’s flagship feature is easy to use and designed to save you time.`,
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: `Simply press "A" and start dragging available times that you'd like to offer. These time slots will be formatted in real-time, ready for you to copy.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.SLOTS),
          label: "Learn more about Slots",
        },
      ],
    },
    {
      header: "Time Travel",
      bodies: [
        {
          text: "Whether you work remotely or have a global clientele, coordinating across time zones is time consuming and error-prone.",
        },
        {
          text: "In Vimcal, you can instantly travel to anywhere in the world to coordinate as if you were there.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TIME_TRAVEL),
          label: "Learn more about Time Travel",
        },
      ],
    },
    {
      header: "Command Center",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Quickly search and navigate to different features and settings by pressing Cmd + K (Mac) or Ctrl + K (PC)!",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.COMMAND_CENTER),
          label: "Learn more about Command Center",
        },
      ],
    },
    {
      header: "Personal Links",
      bodies: [
        {
          text: "Whether you’re looking to offer times for teammates to schedule office hours or creating a static booking link for interviews, Personal Links has you covered!",
        },
        {
          text: `Set your preferred availability per link and start getting calls scheduled.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.PERSONAL_LINKS),
          label: "Learn more about Personal Links",
        },
      ],
    },
    {
      header: "Natural Language (NLP)",
      bodies: [
        {
          text: "In Vimcal, creating an event is as intuitive as describing it in a conversation.",
        },
        {
          text: `There's only one rule - always start with the title first. Everything else can come in any order afterwards.`,
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.NLP),
          label: "Learn more about NLP",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Free Time Finder",
              link: getCompleteURL(CONTENT_PATH.FREE_TIME_FINDER),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Group Vote",
              link: getCompleteURL(CONTENT_PATH.GROUP_VOTE),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Color coding and Tagging",
              link: getCompleteURL(CONTENT_PATH.COLOR_CODING_AND_TAGGING),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Weekly Metrics",
              link: getCompleteURL(CONTENT_PATH.WEEKLY_METRICS),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Templates",
              link: getCompleteURL(CONTENT_PATH.TEMPLATES),
            },
          ],
        },
      ],
    },
  ],
};
const SLOTS_SUBSECTION = {
  name: "Slots",
  path: CONTENT_PATH.SLOTS,
  topics: [
    {
      bodies: [
        {
          text: "We designed availability sharing to always feel fast and personal with Slots — the most popular feature in Vimcal.",
        },
        {
          text: "Instead of tediously typing out each time window you'd like to offer someone, simply drag them on your calendar and we'll type it out for you to copy.",
        },
        {
          text: "Choosing specific times for specific people gives you much more control over your time than using a generic booking link.",
        },
        {
          text: "--- IMAGE OF SLOTS GIF",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement with Slots GIF
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The circular green button at the bottom right of your calendar",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "'A' on your keyboard",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Command Center → “Slots” ",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "Sending Slots in different time zones",
      bodies: [
        {
          text: "When you're scheduling with multiple people in different timezones, easily display all timezones in line with the “Show all time zones option”.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Slots + Time Travel",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "Vimcal features are designed to work in perfect harmony with each other.",
        },
        {
          text: "Combining Slots and Time Travel not only allows you to pick times that work for both you and the person you're scheduling with, but also to send it in their time zone as a courteous personal touch.",
        },
        {
          text: "You can have up to 5 total time zones!",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Displaying all time zones",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "When you're scheduling with people in multiple time zones, you also have the option to display the times for all time zones you selected when Time Travling.in line with the “Show all time zones option”.",
        },
        {
          text: "Simply check the 'Show all time zones' option.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Customizing the copied text",
      bodies: [
        {
          text: "Different situations might call for different writing styles for the times you want to offer. Personalize them to your liking with the options below.",
        },
      ],
    },
    {
      header: "The Four Formats",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "Vimcal offers four formats, with and without booking links, that you can easily copy when sending out your availabilities.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "1) Hyperlinked Text",
          isSubheader: true
        },
        {
          text: "This option blends a booking link naturally in each Slot you offer.",
        },
        {
          text: "Any line the recipient clicks on will shortcut to that day's times in the booking link..",
        },
        {
          text: "You don't lose the personal touch while sending out a booking link since you still handpick individual times for the recipient.",
        },
        {
          text: "Pro Tip: This is the most used format in Vimcal. Since it contains HTML, it's best reserved for email or Slack.",
        },
        {
          text: "--- IMAGE OF HYPERLINKED SLOTS IN EMAIL",
        },
        {
          text: "2) Plaintext Only",
          isSubheader: true
        },
        {
          text: "Sometimes the social dynamic makes sending a booking seem out of place.",
        },
        {
          text: "In these cases, you can copy your availabilities as just text.",
        },
        {
          text: "Pro Tip: This is the most flexible option and can be pasted anywhere.",
        },
        {
          text: "3) Plaintext + URL",
          isSubheader: true
        },
        {
          text: "This option combines the best of both formats above, giving you the flexibility of 'Plaintext Only' with the efficiency of having a booking link.",
        },
        {
          text: "It's also the most 'human' way to fit a booking link into a scheduling conversation.",
        },
        {
          text: "Pro Tip: This is great for text messages or social media DMs if you want both the Slots spelled out and a booking link to be included as it does not contain HTML.",
        },
        {
          text: "4) URL only",
          isSubheader: true
        },
        {
          text: "For when you just need the URL.",
        },
        {
          text: "Pro Tip: Use the shortcut Command + Shift + C (Mac) or Ctrl + Shift + C (PC) to copy just the URL!",
        },
      ],
    },
    {
      header: "Default Style",
      bodies: [
        {
          text: "Once you’ve had a chance to look over each booking link style, you can now choose which style you'd like as the default!",
        },
        {
          text: "In the upper right hand corner, quickly access Slots settings by clicking the gear icon!",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Under the Copyable Text header, choose your default style.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Popular Workflows",
      bodies: [
        {
          text: "This feature reduces the amount of clicks your recipient will need to book the meeting by pre-filling their info. Most importantly, it adds a warm message and thoughtful touch when they open the booking page.",
        },
        {
          text: "All you have to do is add the recipient's name and email beforehand.",
        },
      ],
    },
    {
      header: "Previously sent Slots",
      bodies: [
        {
          text: "Quickly re-surface previous Slots you've copied. This is a time saver when you want to send the same Slots out to multiple people.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Tip: Press 'Enter' to quickly populate the last Slots you selected.",
        },
      ],
    },
    {
      header: "Personalizing the booking link",
      bodies: [
        {
          text: "This feature reduces the amount of clicks your recipient will need to book the meeting by pre-filling their info. Most importantly, it adds a warm message and thoughtful touch when they open the booking page.",
        },
        {
          text: "All you have to do is add the recipient's name and email beforehand.",
        },
      ],
    },
    {
      header: "Using Templates with Slots ",
      bodies: [
        {
          text: "One of our more popular workflows in Vimcal is to leverage an event template and apply it to the slots you’re going to offer. ",
        },
        {
          text: "An example could be scheduling individual onboarding calls for a group.",
        },
        {
          text: "Once you've selected the times you'd like to offer, simply press Command + Semicolon (Mac) or Ctrl + Semicolon (PC) and choose the template you’d like to apply. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "This will pre-fill all details like duration, title, attendees, etc. that you may have added to the template.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Slot Settings",
      bodies: [
        {
          text: "This settings module allows you to further customize and set defaults for future Slots!",
        },
      ],
    },
    {
      header: "Copyable Text",
      bodies: [
        {
          text: "Once you've set your default style, you can choose to edit the specific slots message copy for each booking link style. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Choosing calendars to protect from conflicts",
      bodies: [
        {
          text: "The 'Check for conflicts' feature lets you select which calendars you'd like to prevent double booking on.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Booking links you send out will hide any conflicting events you have on those calendars.",
        },
      ],
    },
    {
      header: "Ignoring conflicts",
      bodies: [
        {
          text: "On the other hand, there are times you'll want to double book over existing meetings on your calendar, like when you need to schedule a high-priority meeting or a job interview.",
        },
        {
          text: "You get two override options in Vimcal:",
        },
        {
          text: "Ignore all conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on if you want the booking link to disregard all conflicts on your calendar and always show all the selected Slots to the recipient.",
        },
        {
          text: "Pro Tip: Reserve this for the highest priority meetings to prevent accidental double bookings.",
        },
        {
          text: "Ignore my internal conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on to allow recipients to book over meetings you define as 'interal'.",
        },
        {
          text: "Pro Tip: Set what company domains or individual email addresses count as an internal meeting in your General Settings.",
        },
        {
          text: "--- ADD LINK TO SECTION OF DOCS ARTICLE FOR INTERNAL DOMAINS. NAME THIS LINK 'View Internal Meeting Settings'",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Custom Questions",
      bodies: [
        {
          text: "Add additional fields in the booking link to collect additional relevant information for your meeting Slots.",
        },
        {
          text: "Some examples include:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Company name",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Phone Number",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Reason for Call",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Any other question of your choosing",
              isIndented: true,
            },
          ],
        },
        {
          text: "You may set Custom Questions as optional or required.",
        },
        {
          text: "--- IMAGE OF CUSTOM QUESTIONS IN BOTH THE SETTING AND LIVE LINK",
        },
      ],
    },
  ],
};

const GROUP_VOTE_SUBSECTION = {
  name: "Group Vote",
  path: CONTENT_PATH.GROUP_VOTE,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "‘Group Vote’ will allow you to find the best meeting time across a larger group.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The circular, light green button at the bottom right of your calendar → ‘Group Vote’ in dropdown",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: `‘A’ on your keyboard  → ‘Group Vote’ in dropdown`,
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Cmd + K (Mac) → or Ctrl + K (PC) and type “Vote” → “View Group Vote links”",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Small icon at the very top left of your calendar, above the time zone (then under ‘Availability Features’)",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "Popular Use Case",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Agreeing on time & day for a company off-site",
          isSubheader: true,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Open the ‘Group Vote’ feature",
            },
            {
              text: "Step 2: Select ‘+ Create’ in the top right of the righthand panel",
            },
            {
              text: "Step 3: Click & drag times you’d like to have as options for voting",
            },
            {
              text: "Step 4: Ensure all settings on the righthand panel are correct",
            },
            {
              text: "Step 5: Either “Share via email” and enter colleagues’ email addresses to Send, or ‘Copy link’ and paste wherever you’d prefer (Slack, Whatsapp, etc)",
            },
            {
              text: "Step 6: Once the Group Vote has been answered by all recipients, re-open the Group Vote’ feature and select ‘+ Create event’",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Step 6: ‘Create’ event and confirm in the Create Event page.",
        },
        {
          text: "Once created, all voters/attendees will receive an email confirming the created event.",
        },
      ],
    },
  ],
};
const TEMPLATES_SUBSECTION = {
  name: "Templates & Sticky Notes",
  path: CONTENT_PATH.TEMPLATES,
  topics: [
    {
      header: "Event Templates",
      bodies: [
        {
          text: "Coffee chats, onboarding calls, interviews. We create certain events over and over again, yet they're not quite recurring.",
        },
        {
          text: `Instead of manually entering the same details each time, you can simply create a template! Hit "Command K" -> "template" and select "Create event template".`,
        },
        {
          text: `Fill out all the details that stay constant for each meeting. For example, coffee meetings might always be called "Coffee Chat", last 45 minutes, and happen at the same neighborhood spot.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `To pre-populate a new event using the template, type "Cmd + ; (Mac) or Ctrl + ; (PC)" or search "Use template" in Command Center. If you've already started creating the event, we'll only apply the fields you haven't modified yet!`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Sticky Notes",
      bodies: [
        {
          text: `Whether it's adding a signature, important links, or just sending a quick email note when you're running late, sticky notes are useful!`,
        },
        {
          text: `Think of this feature as a text template you can easily copy to your clipboard at any time. Press "Cmd + K (Mac) or Ctrl + K (PC)" and type "sticky" to create the title of the sticky note and then paste in that link or type out a quick message.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Once you've saved that Sticky, easily access it by pressing "Cmd + ; (Mac) or Ctrl  + ; (PC)" and typing its title!`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Side note:",
        },
        {
          text: `Stickies can also be used when pulling up the email guest feature.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Simply press Cmd (Mac) or Ctrl (PC) + ; " and type the title of that sticky.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "The context within that Sticky should then be pasted in the message section.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};
const FOCUS_MODE_SUBSECTION = {
  name: "Focus Mode",
  path: CONTENT_PATH.FOCUS_MODE,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Focus Mode is a beautiful, full-page overlay of Vimcal designed to help you jump into deep work and stay there.",
        },
        {
          text: "It features a Pomodoro Timer, ambient noise, and a preview of your upcoming meetings so you can quickly hop back into calls.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "",
        },
      ],
    },
  ],
};
const TAGS_METRICS_SUBSECTION = {
  name: "Tags and metrics",
  path: CONTENT_PATH.TAGS_AND_METRICS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal lets you automatically tag and color code any event so you can quickly visualize how your week is broken down.",
        },
        {
          text: "To label or tag specific events, simply click or focus on the event and press 'P,' or right-click on the event to access the tags feature. From there, create a tag and assign it a color for easy identification.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "To automatically assign tags to specific events, navigate to the Command Center (press Command + K for Mac or Control + K for PC) and select 'Update Tag Settings'.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Click "Create new tag"`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Assign a color, add a label, and establish rules to ensure the tag applies to events that meet specific criteria.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Once done, just hit "Save."`,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Weekly metrics emails will provide a breakdown of the following:",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Internal vs external meetings each week",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Swiss cheese time in your calendar",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Hour or percentage breakdown of the tags you've created within Vimcal",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const TIME_TRAVEL_SUBSECTION = {
  name: "Time Travel",
  path: CONTENT_PATH.TIME_TRAVEL,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Time Travel is the feature that makes converting up to 5 time zones at once a breeze.",
        },
        {
          text: "It's the second most popular feature in Vimcal after Slots. Our goal is to make sure you never have to do time zone mental math ever again.",
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The time zone at the very top left of your Vimcal calendar",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: `‘Z’ on your keyboard`,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Cmd + K (Mac) or Ctrl + K (PC)→ type “Time Travel”",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Or click on the top right section of the Slots panel labeled “Change time zone”",
            },
          ],
        },
      ],
    },
    {
      header: "Looking up time zones",
      bodies: [
        {
          text: "You often know someone's city but not their time zone.",
        },
        {
          text: "To save you a few Google searches, we took the extra step to let you search by city, country, or time zone when you Time Travel!",
        },
        {
          text: "If a country has multiple time zones, we will suggest them all to you. Daylight savings is also automatically accounted for you.",
        },
      ],
    },
    {
      header: "Overlapping Work Hours ",
      bodies: [
        {
          text: "Keeping meetings and other events within work hours is crucial. Quickly setup your preferred working hours in Vimcal ",
        },
        {
          text: "How to Setup: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Press Cmd + K (Mac) or Ctrl + K (PC) and type in settings",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "In the General section, there should be a small sub-section labeled Display.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Here you can adjust your start and end work hours ",
            },
          ],
        },
        {
          text: "Note: This will only display when using the time travel feature!",
        },
      ],
    },
    {
      header: "Slots + Time Travel",
      bodies: [
        {
          text: "Vimcal features are designed to work in perfect harmony with each other. ",
        },
        {
          text: "Combining Slots and Time travel allows each meeting participant to see the proposed times from their prospective timezone when initially viewing the email and again when they select a time! ",
        },
      ],
    },
  ],
};

const TIME_ZONE_SUBSECTION = {
  name: "Time Zones",
  path: CONTENT_PATH.TIME_ZONES,
  topics: [
    {
      header: "Slots + Time Travel ",
      bodies: [
        {
          text: "Vimcal features are designed to work in perfect harmony with each other.",
        },
        {
          text: "Combining Slots and Time travel allows each meeting participant to see the proposed times from their perspective timezone when initially viewing the email and again when they select a time! ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Access the Availability feature & choose ‘Slots’ from the dropdown",
            },
            {
              text: "Step 2: Click & drag desired Slots on the calendar",
            },
            {
              text: "Step 3: Time Travel by selecting ‘Change time zone’ at the top right of the righthand panel",
            },
            {
              text: "Step 4: Enter recipient’s city or time zone",
            },
            {
              text: "Step 5: Copy to clipboard via Cmd + C (Mac) , Ctrl + C (PC) or by selecting the blue button at the bottom of the righthand panel",
            },
            {
              text: "Step 6: Paste",
            },
          ],
        },
        {
          text: "The order (Slots → Time Travel, Time Travel → Slots) doesn’t matter, though you may prefer one vs. another depending on where your recipient is located. If you’re in vastly different timezones, consider Time Traveling and then choosing Slots.",
        },
        {
          text: "This will ensure you’re selecting appropriate times for both parties, right from the start.",
        },
      ],
    },
    {
      header: "Considering 3+ time zones for more people",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Repeat steps 1-4 above",
            },
            { text: "Step 2: Press ‘Z’ to open up Time Travel" },
            {
              text: "Step 3: Enter another city or time zone",
            },
            { text: "Step 4: Do this up to four times, totaling 5 time zones" },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "The large, darker area in the middle between the two, lined areas represents a rough estimate of when all meeting participants might be awake (7am-7pm).",
        },
        {
          text: "Using the single quotation key to the left of ‘return’ on the keyboard will allow you to toggle between the selected time zones. This is useful for gaining a different vantage point and for personalizing your proposed Slots.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Anchoring Time zone",
      bodies: [
        {
          text: "Always needing to have an executive’s time zone handy",
          isSubheader: true,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Press Cmd + K (Mac) or Ctrl + K (PC) to open the Command Center",
            },
            { text: "Step 2: Type ‘anchor’ or ‘time’ " },
            {
              text: "Step 3: Select ‘Update anchor time zones’",
            },
            { imageSrc: "" }, // TODO: add
            {
              text: "Step 4: Add the time zones you’d like to always have in Vimcal",
            },
            { text: "Step 5: Save" },
          ],
        },
      ],
    },
  ],
};

const EXTERNAL_SCHEDULING_SUBSECTION = {
  name: "External Scheduling",
  path: CONTENT_PATH.EXTERNAL_SCHEDULING,
  topics: [
    {
      header: "Find a time to meet with an external party (in person, virtual)",
      bodies: [
        {
          text: "The most consistent workflow in Vimcal is sharing times with external parties (investors, clients, friends). ",
        },
        {
          text: "Here are steps on how to efficiently share your availability! ",
        },
        {
          text: `Step 1: Access the availability feature & choose ‘Slots’ from the dropdown`,
        },
        {
          text: `Step 2: Click & drag desired Slots on the calendar`,
        },
        {
          text: `Bonus: Underneath ‘Invite Details’ is a property called ‘Conferencing’ where you can choose the nature of your meeting (virtual, in person).`,
        },
        {
          text: "If you’d like to add a physical location, expand ‘+ Personalize invite’ to populate the ‘Where’ field.",
        },
        {
          text: "Step 3: Copy to clipboard via Cmd + C (Mac), Ctrl + C (PC), or by selecting the blue button at the bottom of the righthand panel",
        },
        {
          text: "Step 4: Paste",
        },
      ],
    },
    {
      header: "Send times to someone in a different time zone",
      bodies: [
        {
          text: "Now a days everyone is either working or meeting with someone in a different timezone. In Vimcal, we make this process simple! ",
        },
        {
          text: "No more mental math! ",
        },
        {
          text: "Step 1: Access the availability feature & choose ‘Slots’ from the dropdown & Click/drag desired Slots on the calendar",
        },
        {
          text: "Step 2: Time Travel by selecting ‘Change time zone’ at the top right of the righthand panel",
        },
        {
          text: "Step 3: Enter recipient’s city or time zone",
        },
        {
          text: "The order (Slots → Time Travel, Time Travel → Slots) doesn’t matter, though you may prefer one vs. another depending on where your recipient is located. If you’re in vastly different timezones, consider Time Traveling and then choosing Slots.",
        },
        {
          text: "This will ensure you’re selecting appropriate times for both parties, right from the start.",
        },
        {
          text: "Step 4: Copy to clipboard via Cmd + C (Mac), Ctrl + C (PC) or by selecting the blue button at the bottom of the righthand panel",
        },
        {
          text: "Step 5: Paste",
        },
      ],
    },
    {
      header: "Scheduling with a Group ",
      bodies: [
        {
          text: "Have you ever tried to coordinate the best time between 10+ ppl for a board meeting/offsite/bday party? Its tough. ",
        },
        {
          text: "Group Vote Poll’s is the cleanest way to ensure that everyone can provide input on the best time ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Open the ‘Group Vote’ feature",
            },
            {
              text: "Step 2: Select ‘+ Create’ in the top right of the righthand panel",
            },
            {
              text: "Step 3: Click & drag times you’d like to have as options for voting",
            },
            {
              text: "Step 4: Ensure all settings on the righthand panel are correct",
            },
            {
              text: "Step 5: Either “Share via email” and enter colleagues’ email addresses to Send, or ‘Copy link’ and paste wherever you’d prefer (Slack, Whatsapp, etc)",
            },
            {
              text: "Step 6: Once the Group Vote has been answered by all recipients, re-open the Group Vote’ feature and select ‘+ Create event’",
            },
            {
              text: "Step 7: ‘Create’ event and confirm in the Create Event page.",
            },
          ],
        },
        {
          text: "Once created, all voters/attendees will receive an email confirming the created event.",
        },
      ],
    },
    {
      header: "Free Time Finder ",
      bodies: [
        {
          text: "Free time finder comes in handy when you have a packed week and you don’t want to look for opening in your calendar. ",
        },
      ],
    },
    {
      header: "Find Times",
      bodies: [
        {
          text: "By clicking the Find times feature, Vimcal will quickly display the open slots that work for you. ",
        },
        {
          text: "Bonus: If you need to change the range of times that were selected, we offer different options to choose from! ",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Slots",
              link: getCompleteURL(CONTENT_PATH.SLOTS),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Time Travel",
              link: getCompleteURL(CONTENT_PATH.TIME_TRAVEL),
            },
          ],
        },
      ],
    },
  ],
};

const INTERNAL_SCHEDULING_SUBSECTION = {
  name: "Internal Scheduling",
  path: CONTENT_PATH.INTERNAL_SCHEDULING,
  topics: [
    {
      header: "Meet With",
      bodies: [
        {
          text: `In Vimcal users can quickly overlay co-worker’s calendars using Meet with. `,
        },
        {
          text: "Meet with makes it easy to: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Loosely overlay your co-worker’s calendar ",
            },
            { text: "Schedule an event with that teammate(s)" },
            {
              text: "Propose potential meeting times that work for yourself and a teammate or group (Slots) ",
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Press Cmd + J (Mac) or Ctrl + J (PC)",
            },
            { text: "Step 2: Type your colleague’s name" },
            {
              text: "Step 3: Select an open time on the calendar",
            },
            {
              text: "Step 4: Create event",
            },
          ],
        },
        {
          text: "After the event is created, you’ll notice the other person’s calendar will disappear. Cmd + J (Mac) or Ctrl + J (PC) brings up calendars temporarily and will automatically add the other person as an attendee to subsequently created events (rather than manually toggling on a calendar from the bottom right).",
        },
        {
          text: `You can quickly clear all the calendars by hitting the "Esc" key.`,
        },
      ],
    },
    {
      header: "Popular Use cases:",
      bodies: [
        {
          text: "Finding Mutually Free Times with Coworkers",
          isSubheader: true,
        },
        {
          text: "Find Meeting times is a game changer when your co-worker asks you to just schedule any open time for a meeting on their calendar. ",
        },
        {
          text: "When creating that meeting or using the Meet with feature, clicking the meeting option under your teammates name will display the open slots that work for both you and your co-workers' calendars. ",
        },
        {
          text: "Note: That teammate will need to be associated with your company domain in Google or Outlook. ",
        },
      ],
    },
    {
      header: "Contact Groups",
      bodies: [
        {
          text: "If you’re constantly working with different teams with your company or just the same group of people, Contacts groups is a must! ",
        },
        {
          text: "These Groups allow you to bundle certain people together on your team for a variety of uses.",
        },
        {
          text: "Quickly look at Engineering’s calendar",
          isSubheader: true,
        },
        {
          text: "In this example, we’ll create a group for the engineering team! ",
        },
        {
          text: "Step 1: Press Cmd + J (Mac) or Ctrl + J (PC).",
        },
        {
          text: "Step 2: Type “Eng”",
        },
        {
          text: "Again, you’ll now be temporarily viewing everyone calendar who you’ve added to the “Eng team” contact group (as noted by the blue bar at the top):",
        },
      ],
    },
  ],
};

const KEYBOARD_SHORTCUTS_SUBSECTION = {
  name: "Keyboard Shortcuts",
  path: CONTENT_PATH.KEYBOARD_SHORTCUTS,
  topics: [
    {
      header: "Flash Shortcuts",
      bodies: [
        {
          text: "Press the 'F' key to display an overlay of hotkeys next to each feature.",
        },
        {
          text: "This is great for visual learners and those who want to learn the shortcuts at your own pace!",
        },
        {
          text: "Pro Tip: For advanced shortcut users, we also apply a semi-random shortcut to each event on your calendar.",
        },
        {
          text: "--- IMAGE OF FLASHING HOTKEYS",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "View all shortcuts",
      bodies: [
        {
          text: "For a full list of all the keyboard shortcuts in Vimcal, please reference our .",
        },
        {
          text: "You can access it simply by typing '?'.",
        },
        {
          text: "There's also a search interface for yout o quickly query the hotkey you're looking for.",
        },
        {
          text: "--- IMAGE OF SHORTCUTS MENU",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Command Center",
      bodies: [
        {
          text: "The Command Center is Vimcal's central hub where you can access every feature and setting.",
        },
        {
          text: "Access it by hitting Cmd + K (Mac) or Ctrl + K (PC).",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "--- ADD LINK TO COMMAND CENTER DOC WITH TEXT 'Learn more about the Command Center here.'",
        },
      ],
    },
  ],
};

const WEEKLY_METRICS_SUBSECTION = {
  name: "Weekly Metrics",
  path: CONTENT_PATH.WEEKLY_METRICS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Once you’ve set up your tags, Vimcal will curate a weekly metrics report so each user can see a quick breakdown of how you spent your time! ",
        },
        {
          text: "Weekly metrics emails can provide a breakdown of the following:",
        },
      ],
    },
    {
      header: "Internal vs External Meetings ",
      bodies: [
        {
          text: "Quickly see a comparison of internal vs external calls for the previous week! ",
        },
        {
          text: "Additionally, we’ll also let you know if the number of meetings have increased for a given week. ",
        },
        {
          text: "This allows you to move faster and start adjusting your weekly call structure sooner! ",
        },
      ],
    },
    {
      header: "Swiss Cheese time ",
      bodies: [
        {
          text: "Swiss cheese time in your calendar shows you holes of 30m or less that you have in between your meetings.",
        },
        {
          text: "These unproductive gaps aren't enough time to do meaningful work and can be minimized by scheduling more of your meetings back-to-back.",
        },
        {
          text: "We recommend keeping this number to under 2hrs/week.",
        },
      ],
    },
    {
      header: "Yearly Progress Bar",
      bodies: [
        {
          text: "With each Metric Report, we’ll keep you up to date on how much of the year has been completed. ",
        },
        {
          text: "At the end of every year, the team at Vimcal will provide each user with a Vimcal calendar Rewind. This will provide a summary of: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "# of Zoom meetings",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Your calendar persona (my personal favorite)",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Swiss Cheese Time",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Your top tags",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "And more!",
            },
          ],
        },
      ],
    },
  ],
};

const COLOR_CODING_AND_TAGGING_SUBSECTION = {
  name: "Color Coding and Tagging",
  path: CONTENT_PATH.COLOR_CODING_AND_TAGGING,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal lets you automatically tag and color code any event so you can quickly visualize how your week is broken down.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "To label or tag specific events, simply click or focus on the event and press 'P,' or right-click on the event to access the tags feature. From there, create a tag and assign it a color for easy identification.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "To automatically assign tags to specific events, navigate to the Command Center (press Cmd + K (Mac) or Ctrl + K (PC) and select 'Update Tag Settings'.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: `Click "Create new tag"`,
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Assign a color, add a label, and establish rules to ensure the tag applies to events that meet specific criteria.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: `Once done, just hit "Save."`,
            },
          ],
        },
      ],
    },
  ],
};

const FREE_TIME_FINDER_SUBSECTION = {
  name: "Free Time Finder",
  path: CONTENT_PATH.FREE_TIME_FINDER,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "With the help of Free Time Finder, Vimcal can assist you in highlighting: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Open meeting times within your calendar ",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "Open slots of availability within a packed calendar ",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Find Meeting Times",
      bodies: [
        {
          text: "When creating a meeting or using the Meet with feature, clicking the meeting option under your teammate’s name will display the open slots that work for both you and your co-workers' calendars. ",
        },
        {
          text: "Note: That teammate will need to be associated with your company domain in Google or Outlook. ",
        },
      ],
    },
    {
      header: "Find Times",
      bodies: [
        {
          text: "By clicking the Find times feature, Vimcal will quickly display the open slots that work for you. ",
        },
        {
          text: "Bonus: If you need to change the range of times that were selected, we offer different options to choose from!",
        },
        {
          text: "Just click the preference you’d like or press the shortcut that corresponds to that specific timeframe. ",
        },
      ],
    },
  ],
};

const FAQ_SUBSECTION = {
  hideChapters: true,
  name: "Frequently asked questions",
  path: CONTENT_PATH.FAQ,
  topics: [
    {
      header: "Can I have multiple accounts?",
      bodies: [
        {
          text: "Easily add additional accounts to Vimcal by pressing command + K / Ctrl + K (for Windows) and typing out add account. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I see all my calendars in one view?",
      bodies: [
        {
          text: "Yes! After adding an account to Vimcal, you can easily toggle on your preferred calendar view under the calendar list in the lower left-hand side panel. ",
        },
      ],
    },
    {
      header: "Do you have a desktop app?",
      bodies: [
        {
          text: "Yes! To download the desktop app, users can press command + K and type download.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Note:",
            },
            { text: "Apple M1, M2, M3 - Apple Silicon", isIndented: true },
            { text: "Other Mac versions & builds - Intel", isIndented: true },
            { imageSrc: "" }, // TODO: add
          ],
        },
      ],
    },
    {
      header: "Do the booking links update automatically?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header: "Can I add multiple time zones to Time Travel?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header:
        "Can I use a different anchor time zone for Time Travel? How many time zones can I overlay at one time?",
      bodies: [
        {
          text: "Absolutely! You can add and view up to 5 time zones  using Time Travel.",
        },
      ],
    },
    {
      header: "What are “tags”?",
      bodies: [{ text: "Tags let you assign labels and colors to an event." }],
    },
    {
      header: "Why should I use tags?",
      bodies: [
        {
          text: "We recommend using tags so you can quickly see how your week is broken down and improve your productivity.",
        },
      ],
    },
    {
      header: "Can I duplicate an event?",
      bodies: [
        {
          text: "Sure! You can easily duplicate an event by going to the Command Center via ⌘ + K (Ctrl + K on a PC) > search for “Duplicate”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You can also do this by clicking the event > type “YP” > then “Create Event” to duplicate it.",
        },
        {
          text: "Another option is to click the event > go to the menu (3 dots) > click on Duplicate",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I copy an event to a different calendar?",
      bodies: [
        {
          text: "You can copy an event by going to the Command Center via ⌘ + K (Ctrl + K on a PC) > search for “Copy to email address” > Create Event and you’re all set!",
        },
      ],
    },
    {
      header:
        "I don’t want to see the weekends on my calendar. How do I hide them?",
      bodies: [
        {
          text: "You can hide them via the Command Center ⌘ + K (Ctrl + K on a PC) > search for “weekend” ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I search other calendars other than my primary calendar?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header: "How do I search for an event?",
      bodies: [{ text: `Yes! Press “/” then search for the event title.` }],
    },
    {
      header: "Do you have a mobile app?",
      bodies: [
        {
          text: "Yes, we do! To download the mobile app, users can press ⌘ + K (Ctrl + K on a PC) and type download. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What are slots?",
      bodies: [
        {
          text: "Slots enable you to create a booking link, allowing you to easily share your availability with others.",
        },
      ],
    },
    {
      header:
        "Can I customize the text that populates for Slots? If so, where can I find it?",
      bodies: [
        {
          text: "Definitely! Simply click the gear icon for Slots and choose if you want to modify copy for Hyperlinked Text, Plaintext + URL, or Plaintext only.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You can modify the Pre-slots copy (and Link copy for Plaintext + URL) then hit “Save” to apply the changes.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I change the times I’m available for a Personal Link?",
      bodies: [
        {
          text: "Press Shift + Y to pull up Personal Link and click the pencil icon right next to the link you want to modify.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You’ll see this page where you can simply select, remove, or drag time blocks depending on your availability.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What’s the difference between Slots and Personal Links?",
      bodies: [
        {
          text: "With Slots, you just drag the times you want to offer someone and we'll type up a personalized message (with time zone & link) for you to easily copy. This expires after it’s past the specific dates and times that were offered.",
        },
        {
          text: "On the other hand, Personal Links are static booking links used for ongoing availability.",
        },
      ],
    },
    {
      header: "What are event templates?",
      bodies: [
        {
          text: `Instead of manually entering the same details for your events, you can simply create a template! This saves you time when you have to create certain events over and over again. Simply Hit "Command K" -> "template" and select "Create event template."`,
        },
      ],
    },
    {
      header: "What’s a sticky?",
      bodies: [
        {
          text: "If you want to save notes, an email template, or anything that you'd like to quickly copy and paste as you create your events then you can make use of Sticky. ",
        },
        {
          text: "You can create a Sticky by going to the Command Center and type “Create a Sticky.”",
        },
        {
          text: `To use or modify your sticky notes, just go back to the Command Center and search for "Go to templates" to edit them or "Use Templates" to apply them. `,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I view all the shortcuts in the app somewhere?",
      bodies: [
        {
          text: "Yes! Just type “?” and it will display all the keyboard shortcuts.",
        },
      ],
    },
    {
      header: "What is “Launch and copy Zoom personal link?”",
      bodies: [
        {
          text: "This will open your Zoom Personal Meeting and copy the meeting link at the same time.",
        },
      ],
    },
    {
      header: "Can I create events with unique zoom links?",
      bodies: [
        {
          text: "Yes! Just head over to the Command Center and search for “Zoom Settings”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Make sure to toggle off “Default to using Personal Meeting Link” so it generates unique Zoom meeting links.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header:
        "How do I remove the “Created with Vimcal.com” text in the event description?",
      bodies: [
        {
          text: "You can remove the Vimcal signature by manually deleting it from the event description or you can permanently do so by hitting Command K > type “Remove Vimcal signature”",
        },
      ],
    },
    {
      header: "I want to receive desktop notifications. How do I turn them on?",
      bodies: [
        { text: "Go to the Command Center and search for “Up Next”" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Make sure to tick the boxes of all the calendars you want to receive notifications for and hit “Save.”",
        },
        {
          text: "After that, click the Apple icon on the upper-left hand corner of your desktop and go to “System Settings:”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        { text: "Search for Notifications > Vimcal" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Toggle on “Allow Notifications” and configure the rest of the settings shown below:",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What is Group vote?",
      bodies: [
        {
          text: "Group vote allows you to find the best meeting time across a larger group where your guests/colleagues can place in votes for their preferred meeting time.",
        },
      ],
    },
    {
      header: "Can I open Gmail from Vimcal?",
      bodies: [
        { text: "Yes! Just search for “Open Gmail” in the Command Center." },
      ],
    },
    {
      header: "Can I open Google Calendar from Vimcal?",
      bodies: [
        {
          text: "Of course! Simply search for “Open Google Calendar” in the Command Center.",
        },
      ],
    },
    {
      header:
        "What’s the difference between the browser app and the desktop app?",
      bodies: [
        {
          text: "The browser app is when you use an internet browser like Chrome, Firefox, Safari, etc. ",
        },
        {
          text: "The desktop app is an actual application that you install on your computer (Windows/Mac). With the Mac desktop app you’ll also have a view of upcoming events within your Mac menu bar.  ",
        },
      ],
    },
    {
      header:
        "Is there a shortcut to switch between all the accounts I’m logged into?",
      bodies: [
        {
          text: "You can use “Ctrl + 1/2/3/…” to switch between your calendars accounts.",
        },
      ],
    },
    {
      header: "What’s the “Y1” shortcut for this Personal Link?",
      bodies: [
        {
          text: "Y1 is used to copy slots to your clipboard and you can paste or share it with your guests.",
        },
      ],
    },
    {
      header: "Can I embed my personal links on my website?",
      bodies: [
        {
          text: "Yes! Under the Personal Link you want to embed, click the drop-down for “Share” and select “Add to website.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How can I see another colleague’s calendar?",
      bodies: [
        {
          text: "You can press Command + J and search for your colleague to view their calendar.",
        },
      ],
    },
    {
      header:
        "Can Vimcal find probable meeting times that would work for both myself and my colleague(s) availability?",
      bodies: [
        {
          text: "Yes! While using the “Meet With” function, you’ll see the option for AI to find times for you using “Meeting” or “Slots” on the sidebar just below their name.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I get Dark Mode to match my OS preference?",
      bodies: [
        {
          text: "You can search for “Dark mode” in Command Center and select “Match OS Color Scheme.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Do you guys have a team plan?",
      bodies: [{ text: "We do!" }],
    },
    {
      header:
        "How does NLP work when creating an event? How does it detect what I want?",
      bodies: [
        {
          text: "The NLP parses details as you type and creates the event based on what was entered. ",
        },
        {
          text: "There's only one rule: start with the title of the event. After that, you can type out the details in any order. We detect natural language by keyword:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Date and time (no keywords)",
              isSubheader: true,
            },
            { text: `"Coffee Wednesday at 3pm"` },
            {
              text: `"Coffee 11/3 4-6pm"`,
            },
            { text: `"Coffee tomorrow at noon"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"For" → duration`,
              isSubheader: true,
            },
            { text: `"Coffee for 45 minutes"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"With" → attendees`,
              isSubheader: true,
            },
            { text: `"Coffee with alex@vimcal.com"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"At" → location`,
              isSubheader: true,
            },
            { text: `"Coffee at Four Barrel"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Time zone`,
              isSubheader: true,
            },
            { text: `"Coffee at 3PM EST"` },
          ],
        },
        {
          text: "Default conferencing (no keywords) is automatically added when you invite someone else.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Here are 2 examples combining everything. They have the same information but in different orders. The bolded text is the title.`,
            },
            {
              text: `"Coffee tomorrow at 5pm CST with alex@vimcal.com at Philz Coffee for 45 minutes"`,
              isIndented: true,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Coffee at Philz Coffee with alex@vimcal.com for 45 minutes tomorrow at 5pm CST"`,
              isIndented: true,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "How do you select a different calendar through NLP?",
      bodies: [
        {
          text: "Use “/” and then select the calendar you want the event associated with.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What calendars does the menu bar for the desktop app display?",
      bodies: [
        { text: "Follow up: Why is it not displaying my personal calendar?" },
      ],
    },
    {
      header:
        "For the menu bar, when I have multiple meetings that start at the same time and I hit cmd shift j, how does it know which meeting to join?",
      bodies: [
        {
          text: "After pressing Command + Shift + J, it will bring up this modal where you can select which meeting you’d like to join.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Why do you guys need all these permissions?",
      bodies: [
        {
          text: "Permissions are needed for you to get the full experience of the app. That way, it syncs with all your calendars",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Why do you need domain users?",
            },
            {
              text: "The first permission is for Conference Rooms and the ability to overlay co-workers’ calendars within your organization ",
              isIndented: true,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Why do you need my contacts? Are you going to spam my contacts? ",
            },
            {
              text: "Autocomplete people’s emails - We don’t store their contacts. When they log out, the contacts are wiped from their browser",
              isIndented: true,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "See, edit, share, and permanently delete all calendars you can access using Google Calendar. Are you going to “delete” all my calendar and my events?",
            },
            {
              text: "Read/Write - this looks scary but it’s the most basic calendar permission. This is for creating events, inviting people, RSVPing",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "How do I report bugs/send feedback?",
      bodies: [
        { text: "Press Command + K > “support” > choose from the selection." },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I try the app out for a week before paying?",
      bodies: [{ text: "Sure! You get a 7-day free trial upon signing up." }],
    },
    {
      header:
        "Can I change my events setting to always allow my guests to modify the event?",
      bodies: [
        {
          text: "Absolutely! Go to the Command Center via ⌘ + K (Ctrl + K on a PC) > search “General” > Event Permissions > tick the checkbox for Modify events.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header:
        "Can I add someone’s email and name to availability slots to make it more personal?",
      bodies: [
        { text: "Yes! Select “Personalize Invite”" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        { text: "Then, add the details for your guest." },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can Slots account for my colleague’s availability?",
      bodies: [], // TODO: implement
    },
    {
      header:
        "When using Slots, am I able to add a scheduling buffer to the times I’m sending out?",
      bodies: [
        {
          text: "You can! Go to Slots (press “A”) > Click the gear icon > scroll down to “Scheduling Buffer.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const EVENTS_SUBSECTION = {
  name: "Events",
  path: CONTENT_PATH.EVENTS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "E - edit an event",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "R - reschedule an event",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "GE - email guest",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Use sticky notes templates",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Command + O - Instant open",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "GA - Edit Attendee list",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const GET_STARTED = {
  name: "Get Started",
  sections: [
    WHAT_IS_VIMCAL_SUBSECTION,
    MOST_POPULAR_SUBSECTION,
    CONFERENCING_SUBSECTION,
  ],
};

const FEATURES = {
  name: "Features",
  sections: [
    SLOTS_SUBSECTION,
    COMMAND_CENTER_SUBSECTION,
    TIME_TRAVEL_SUBSECTION,
    PERSONAL_LINKS_SUBSECTION,
    NLP_SUBSECTION,
  ],
};

const ADVANCED_FEATURES = {
  name: "Advanced Features",
  sections: [
    COLOR_CODING_AND_TAGGING_SUBSECTION,
    WEEKLY_METRICS_SUBSECTION,
    FREE_TIME_FINDER_SUBSECTION,
    TEMPLATES_SUBSECTION,
    GROUP_VOTE_SUBSECTION,
    FOCUS_MODE_SUBSECTION,
    INSTANT_OPEN_SUBSECTION,
  ],
};

const ACCOUNT_AND_BILLING = {
  name: "Account & Billing",
  sections: [
    ACCOUNT_SETUP_SUBSECTION,
    TEAMS_SUBSECTION,
    EDIT_YOUR_PROFILE_SUBSECTION,
    REFER_A_FRIEND_SUBSECTION,
  ],
};

const BEST_PRACTICES = {
  name: "Best Practices",
  sections: [
    TIME_ZONE_SUBSECTION,
    EXTERNAL_SCHEDULING_SUBSECTION,
    INTERNAL_SCHEDULING_SUBSECTION,
    KEYBOARD_SHORTCUTS_SUBSECTION,
  ],
};
const FAQS = {
  name: "FAQs",
  sections: [FAQ_SUBSECTION],
};

export const SECTIONS = [
  GET_STARTED,
  ACCOUNT_AND_BILLING,
  FEATURES,
  ADVANCED_FEATURES,
  BEST_PRACTICES,
  FAQS,
];

const PATH_TO_SUBSECTION_MAP = {
  [CONTENT_PATH.ABOUT]: WHAT_IS_VIMCAL_SUBSECTION,
  [CONTENT_PATH.ACCOUNT_SETUP]: ACCOUNT_SETUP_SUBSECTION,
  [CONTENT_PATH.CONFERENCING]: CONFERENCING_SUBSECTION,
  [CONTENT_PATH.TEAMS]: TEAMS_SUBSECTION,
  [CONTENT_PATH.EDIT_YOUR_PROFILE]: EDIT_YOUR_PROFILE_SUBSECTION,
  [CONTENT_PATH.MOST_POPULAR]: MOST_POPULAR_SUBSECTION,
  [CONTENT_PATH.SLOTS]: SLOTS_SUBSECTION,
  [CONTENT_PATH.GROUP_VOTE]: GROUP_VOTE_SUBSECTION,
  [CONTENT_PATH.TEMPLATES]: TEMPLATES_SUBSECTION,
  [CONTENT_PATH.FOCUS_MODE]: FOCUS_MODE_SUBSECTION,
  [CONTENT_PATH.TAGS_AND_METRICS]: TAGS_METRICS_SUBSECTION,
  [CONTENT_PATH.TIME_ZONES]: TIME_ZONE_SUBSECTION,
  [CONTENT_PATH.INTERNAL_SCHEDULING]: INTERNAL_SCHEDULING_SUBSECTION,
  [CONTENT_PATH.EXTERNAL_SCHEDULING]: EXTERNAL_SCHEDULING_SUBSECTION,
  [CONTENT_PATH.KEYBOARD_SHORTCUTS]: KEYBOARD_SHORTCUTS_SUBSECTION,
  [CONTENT_PATH.EVENTS]: EVENTS_SUBSECTION,
  [CONTENT_PATH.FAQ]: FAQ_SUBSECTION,
  [CONTENT_PATH.FREE_TIME_FINDER]: FREE_TIME_FINDER_SUBSECTION,
  [CONTENT_PATH.COLOR_CODING_AND_TAGGING]: COLOR_CODING_AND_TAGGING_SUBSECTION,
  [CONTENT_PATH.WEEKLY_METRICS]: WEEKLY_METRICS_SUBSECTION,
  [CONTENT_PATH.REFER_A_FRIEND]: REFER_A_FRIEND_SUBSECTION,
  [CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE]: POSSIBLE_ISSUES_GOOGLE_SUBSECTION,
  [CONTENT_PATH.PERSONAL_LINKS]: PERSONAL_LINKS_SUBSECTION,
  [CONTENT_PATH.COMMAND_CENTER]: COMMAND_CENTER_SUBSECTION,
  [CONTENT_PATH.NLP]: NLP_SUBSECTION,
  [CONTENT_PATH.INSANT_OPEN]: INSTANT_OPEN_SUBSECTION,
  [CONTENT_PATH.TIME_TRAVEL]: TIME_TRAVEL_SUBSECTION,
};

export function getSubSectionContentFromPath(path) {
  return PATH_TO_SUBSECTION_MAP[path] ?? WHAT_IS_VIMCAL_SUBSECTION;
}
